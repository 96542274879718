
import { lang } from '@/lang'
import { Vue, Component } from 'vue-property-decorator'
import { EventBus, eventBus } from '@/utils/event'

@Component({})
export default class SearchInput extends Vue {
  private keyword!: string

  private get placeholder () {
    return lang('SEARCHINPUT_PLACEHOLDER')
  }

  private clear () {
    this.keyword = ''
  }

  private get showClear () {
    return this.keyword.length > 0
  }

  private get isReferrerPage () {
    return this.$route.path.includes('/referrer')
  }

  private data () {
    return {
      keyword: this.$route.query.keyword || ''
    }
  }

  private emitKeywordChange = (e: KeyboardEvent) => {
    const newVal = (e.target as HTMLInputElement).value
    if (newVal === this.$route.query.keyword) return

    this.$router.replace({
      name: 'search',
      query: {
        keyword: newVal
      }
    })
  }

  private setValue (val:string) {
    this.keyword = val
  }

  mounted (): void {
    eventBus.on(EventBus.Types.HEADER_SEARCH_KEYWORD_CHANGE, this.setValue)
    this.$once('hook:beforeDestroy', () => {
      eventBus.off(EventBus.Types.HEADER_SEARCH_KEYWORD_CHANGE, this.setValue)
    })
  }
}
