
import { Vue, Component } from 'vue-property-decorator'
import SearchInput from './components/SearchInput.vue'

@Component({
  components: {
    SearchInput
  }
})
export default class NewNamesConsole extends Vue {

}
